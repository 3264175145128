body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiDialog-paper {
  width: 300px;
}

.MuiDrawer-paperAnchorBottom {
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  width: 100%;
  flex: 1;
}

.Selected-task > .MuiModal-backdrop {
  background-color: transparent;
}

#root {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
